import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Home from "./pages/Home";
import NavBar from "./components/NavBar";
import NotFound from "./components/NotFound";
import Cart from "./pages/Cart";
import CheckoutPage from "./pages/CheckoutPage";

import Footer from "./components/Footer";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <NavBar />
        <div className="content-container">
          <Routes>
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkoutPage" element={<CheckoutPage />} />
            
            <Route path="/not-found" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            
            <Route path="*" element={<Navigate to="/not-found" />} />
          </Routes>
        </div>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
