import React from 'react';
import './Footer.css'; // Asegúrate de crear este archivo CSS

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
         <h2 style={{display:"flex",}}><p style={{color:"#ffd700"
  }}>Mejor</p>Computo</h2>
        </div>
        <div className="footer-links">
          <a href="#link1">Asesor</a>
          <a href="#link2">Grandes pedidos</a>
          <a href="#link3">Trabajo con nosotros</a>
          <a href="#link4">Servicio al cliente</a>
          <a href="#link5">Reclamos</a>
        </div>
        <div className="footer-socials">
          <a href="#facebook">Facebook</a>
          <a href="#tiktok">TikTok</a>
          <a href="#instagram">Instagram</a>
          <a href="#linkedin">LinkedIn</a>
          <a href="#youtube">YouTube</a>
        </div>
        <div className="footer-contact">
          <p>Teléfono: +57 3053550028</p>
          <p>Dirección: Calle 1sur 25 11, Medellín</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Realizado por Amkydigital.com</p>
      </div>
    </footer>
  );
}

export default Footer;
