import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import { addToCart } from "../slices/cartSlice";
import { useGetAllProductsQuery } from "../slices/productsApi";
import "./Home.css"
const Home = () => {
  const { items: products, status } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const { data, error, isLoading } = useGetAllProductsQuery();
  console.log("Api", isLoading);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    navigate("/cart"); 
  };

  return (


    <>
    

    <div class="home-head">
    <h2>Componentes de Cómputo de Alta Calidad</h2>
    <p>En EstiloCode, encuentra una amplia selección de piezas de cómputo de alta calidad.
        Desde GPUs y procesadores hasta teclados y memorias RAM, nuestros productos están diseñados para
        ofrecer el mejor rendimiento. Compra fácil y seguro, con envíos rápidos.
    </p>
    <span>¡Actualiza tu equipo hoy!</span>
</div>


    <div className="home-container">
      {status === "success" ? (
        <>




          <h2>Mejor Computo</h2>
          <div className="products">     
            {data &&
              data?.map((product) => (
                <a onClick={() => handleAddToCart(product)}>
                <div key={product.id} className="product">
                  <h3>{product.name}</h3>
                  <img src={product.image} alt={product.name} />
                  <div className="details">
                    <span>{product.desc}</span>
                    <span className="price">${product.price}</span>
                  </div>          
                </div>
                </a>
              ))}
          </div>
        </>
      ) : status === "pending" ? (
        <p>Loading...</p>
      ) : (
        <p>Unexpected error occurred...</p>
      )}
    </div>
    </>
    
  );
};

export default Home;
